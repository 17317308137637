<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="info" v-if="item.lesson_level">{{ $t(`lesson.${item.lesson_level}`)}}</div>
                <div class="card-title">{{ $t('lesson.title')}}: {{ item.lesson_title }}</div>
            </div>
            <div class="card-body" id="table">
                <div class="tr">
                    <div class="td text-title">
                        {{ $t('lesson.datetime')}}
                    </div>
                    <div class="td w-100 text-descr">
                        <template v-if="item.lesson_schedule_start_datetime">
                            {{ formatDateJa(item.lesson_schedule_start_datetime, "YYYY年MMMMDD日") }}  {{ _formatTime(item.lesson_schedule_start_datetime, item.lesson_schedule_end_datetime) }}
                        </template>
                        <template v-else>
                            {{ $t('lesson.require_booked_date')}} 
                        </template>
                    </div>
                </div>
                <div class="tr">
                    <div class="td text-title">
                        {{ $t('lesson.teacher')}}
                    </div>
                    <div class="td w-100 text-descr">
                        {{ item.teacher_name }}
                    </div>
                </div>
                <div class="tr">
                    <div class="td text-title">
                        {{ $t('lesson.student')}}
                    </div>
                    <div class="td w-100 text-descr">
                        {{ item.student_name }}
                    </div>
                </div>
            </div>
            <div class="text-center action-block">
                <router-link :to="item.talk_room_url" 
                    class="btn btn-primary">
                    {{ $t('lesson.go_to_talk_room')}}
                </router-link>
                <button class="btn" 
                    @click="handleShow" 
                    v-if="!item.disable_cancelled">
                    {{ $t('lesson.cancel-lesson')}}
                </button>
               
            </div>
        </div>
        <CancelLesson 
            :isVisible="isVisible"
            @cancel="handleHide"
            :item="item"
            :transactionId="item.transaction_id"
            style="z-index: 999"
        />
    </div>
</template>
<script>
import CancelLesson from './cancel-lesson.vue';
export default {
    name: "ItemOngoing",
    components: { 
        CancelLesson
    },
    data(){
        return {
            isVisible: false,
        }
    },
    props: {
        item: {}
    },
    methods: {
        handleShow(){
            this.isVisible = true;
        },
        handleHide(status = false){
            if(status == true){
                this.$emit("loadData");
                this.isVisible = false;
            }else{
                this.isVisible = false;
            }
        },
        _formatTime(start, end) {
            return (
                this.dateInstance(start).format("HH:mm") +
                " - " +
                this.dateInstance(end).format("HH:mm")
            );
        },
    },
}
</script>