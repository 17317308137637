<template>
    <div class="list-lesson">
        <div class="title">
            <h3> 
                {{$t("lesson.list-title")}}
            </h3>
        </div>
        <div class="body">
            <div class="switcher">
                <div>
                    <label>
                       表示：
                    </label>
                    <label
                        :class="`click-view ${filters.tab=='ongoing' && 'disabled'}`" 
                        @click="handleSwitch('ongoing')">
                        {{$t("lesson.switch.ongoing")}} 
                    </label> 
                    <label
                        :class="`click-view ${filters.tab=='finished' && 'disabled'}`" 
                        @click="handleSwitch('finished')">
                        {{$t("lesson.switch.finished")}} 
                    </label> 
                    <label
                        :class="`click-view ${filters.tab=='cancelled' && 'disabled'}`" 
                        @click="handleSwitch('cancelled')">
                        {{$t("lesson.switch.cancelled")}} 
                    </label>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <div v-if="filters.tab=='cancelled'">
                        <CancelledLesson
                        />
                    </div>
                    <div v-else-if="filters.tab=='finished'">
                        <FinishedLesson
                        />
                    </div>
                    <div v-else>
                        <OngoingLesson
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OngoingLesson from './ongoing-lesson.vue';
import FinishedLesson from './finished-lesson.vue';
import CancelledLesson from './cancelled-lesson.vue';
export default {
    components:{
        OngoingLesson,
        FinishedLesson,
        CancelledLesson
    },
    data(){
        return {
            filters: {
                tab: "ongoing"
            },
        }
    },
    async created(){
        this.filters = await this.$router.getQuery(this.filters);
        if(!this.filters.tab){
            this.filters = {
                ...this.filters,
                tab: "ongoing"
            }
        }
    },
    methods: {
        handleSwitch(tab){
            if(tab == this.filters.tab)return;
            this.filters = {
                ...this.filters,
                tab
            }
            this.$router.setQuery({tab});
        },
    }
}
</script>