<template>
    <div class="list">
        <template v-if="list.data.length > 0">
            <div class="item" v-for="(item, key) in list.data" :key="key">
                <ItemOngoing 
                    :item="item" 
                    @loadData="loadData"/>
            </div>
        </template>
        <template v-else>
            <EmptyItem />
        </template>
        <Pagination
                :length="list.meta.pagination.last"
                v-model="filters.page"
                @input="onPageChange"
                v-if="list.meta.pagination && list.meta.pagination.last > 1"
            />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ItemOngoing from './components/item-ongoing.vue';
import EmptyItem from './components/empty.vue';
export default {
    name: "OngoingLesson",
    components: { 
      ItemOngoing,
      EmptyItem
    },
    data() {
        return {
            filters: {
                page: 1,
                tab: "ongoing",
            },
            request: {
                type: "ongoing",
                limit: 10,
                page: 1,
            }
        };
    },
    async created() {
       await this.loadData();
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.filters = this.$router.getQuery(this.filters);
                this.$router.setQuery(this.filters);
                this.request = {
                    ...this.request,
                    page: this.filters.page,
                };
                this.getLessons(this.request);
            },
        },
    },
    computed: {
        ...mapGetters({
            list: "mypage/getMyPageOngoingLesson"
        })
    },
    methods: {
        ...mapActions({
            getLessons: "mypage/fetchMypageLessonList"
        }),
        onPageChange() {
            this.$router.setQuery(this.filters);
        },
        async loadData(){
            this.filters = await this.$router.getQuery(this.filters);
            this.request = {
                ...this.request,
                page: this.filters.page,
            };
            await this.getLessons(this.request);
        }
    },
}
</script>