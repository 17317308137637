<template>
    <div class="list">
      <template v-if="list.data.length > 0">
        <div class="item" v-for="(item, key) in list.data" :key="key">
          <div class="card">
            <div class="card-header">
              <div class="info" v-if="item.lesson_level">{{ $t(`lesson.${item.lesson_level}`)}}</div>
              <div class="card-title">{{ $t('lesson.title')}}: {{ item.lesson_title }}</div>
            </div>
            <div class="card-body" id="table">
                <div class="tr">
                    <div class="td text-title">
                      {{ $t('lesson.datetime')}}
                    </div>
                    <div class="td w-100 text-descr">
                      <template v-if="item.lesson_schedule_start_datetime">
                        {{ formatDateJa(item.lesson_schedule_start_datetime, "YYYY年MMMMDD日") }}  {{ _formatTime(item.lesson_schedule_start_datetime, item.lesson_schedule_end_datetime) }}
                      </template>
                    </div>
                </div>
                <div class="tr">
                    <div class="td text-title">
                      {{ $t('lesson.teacher')}}
                    </div>
                    <div class="td w-100 text-descr">
                      {{ item.teacher_name }}
                    </div>
                </div>
                <div class="tr">
                    <div class="td text-title">
                      {{ $t('lesson.student')}}
                    </div>
                    <div class="td w-100 text-descr">
                      {{ item.student_name }}
                    </div>
                </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
          <EmptyItem />
      </template>
      <Pagination
          :length="list.meta.pagination.last"
          v-model="filters.page"
          @input="onPageChange"
          v-if="list.meta.pagination && list.meta.pagination.last > 1"
        />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EmptyItem from './components/empty.vue';

export default {
    name: "cancelled",
    components: {
      EmptyItem
    },
    data() {
        return {
            filters: {
                page: 1,
                tab: "cancelled",
            },
            request: {
              type: "cancelled",
              limit: 10,
              page: 1,
            },
            isVisible: false,
            lessonData: null
        };
    },
    async created() {
        this.filters = await this.$router.getQuery(this.filters);
        this.request = {
              ...this.request,
              page: this.filters.page,
          };
        await this.getLessons(this.request);
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.filters = this.$router.getQuery(this.filters);
                this.$router.setQuery(this.filters);
                this.request = {
                    ...this.request,
                    page: this.filters.page,
                };
                this.getLessons(this.request);
            },
        },
    },
    computed: {
        ...mapGetters({
            list: "mypage/getMyPageCancelledLesson"
        })
    },
    methods: {
        ...mapActions({
            getLessons: "mypage/fetchMypageLessonList"
        }),
        onPageChange() {
            this.$router.setQuery(this.filters);
        },
        _formatTime(start, end) {
            return (
                this.dateInstance(start).format("HH:mm") +
                " - " +
                this.dateInstance(end).format("HH:mm")
            );
        },
    },
}
</script>