<template lang="">
    <div class="item empty">
        <div class="card">
            <div class="card-body text-center">
                <h4>
                    {{$t("common.empty")}}
                </h4>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>